const Options = {
  navigation: "center",
  layout: "content",
  header: {
    width: "100vw",
  },
  mobileMenu: {
    width: "60vw",
    transition: "slideRight",
    closeOnBlur: true,
    closeOnRouteChange: true,
  },
  content: {
    maxWidth: "100vw",
    maxWidthSection: "100vw",
    breakIndex: 0,
  },
  contentHeaderText: {
    padding: "40px 0px",
    backgroundColor: "rgb(223, 242, 246)",
    border: "1px solid rgb(211, 213, 210)",
    textAlign: "center",
  },
  footer: {
    maxWidth: "100vw",
  },
};

export default Options;
