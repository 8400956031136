const themeStyle = {
  initialColorModeName: "light",
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#1858dc",
    secondary: "#355cac",
    accent: "#1858dc",
    muted: "#f6f6f6",
    border: "#e6e6e6",
    bg_topbar: "#355cac",
    bg_header: "#fff",
    bg_mobileNav: "#c6c6c6",
    bg_sideNav: "#eee",
    bg_footer: "",
    footer_bg: "#fff",
    bg_mobileMenu: "#fff",
  },
  background: {
    footer_bg: "linear-gradient(#2FB383, #18A86B)",
    footer_bg_forest: "linear-gradient(#B7D389, #4d8f15)",
    response_bg: "rgb(223, 242, 246)",
  },
  fonts: {
    body: "gotham-book, opensans-book",
    heading: "gotham-book",
  },
  buttons: {
    primary: {
      // bg:'#418808',
      padding: "8px 20px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#fff !important",
      borderRadius: "50px",
      fontSize: "18px",
      letterSpacing: "0.4px",
    },
  },
  input: {
    width: "100px",
    marginRight: "20px",
  },
  breakpoints: ["768px", "960px", "1240px"],
  styles: {
    root: {
      fontFamily: "body",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "1.5",
      color: "#212529",
      a: {
        display: "inline-block",
        textDecoration: "none",
        color: "#288bd0",
      },
      li: {
        display: "block",
        listStyle: "none",
      },
      h1: {
        fontWeight: 300,
      },
      h5: {
        fontSize: "18px",
        fontWeight: "bold",
      },
      p: {
        fontSize: "18px",
      },
      b: {
        display: "inline-block",
      },
      i: {
        display: "inline-block",
      },
      span: {
        display: "inline-block",
      },
    },
  },
  // Maker UI layout variants
  header: {
    py: [2, "10px"],
    p: 10,
    borderBottom: "1px solid",
    borderColor: "border",
    fontFamily: "heading",
    logo: {},
    menu: {
      a: {
        fontSize: 2,
        fontWeight: 400,
        color: "primary",
        textDecoration: "none",
        py: 3,
        px: 3,
      },
    },
    div: {},

    submenu: {},
    colorButton: {},
  },
  navbar: { flexDirection: "row !important" },
  mobileMenu: {
    ul: {
      p: 0,
    },
    li: {
      p: 10,
      borderTop: "1px solid rgba(255,255,255,0.05)",
      borderBottom: "1px solid rgba(0,0,0,0.35)",
    },
    fontSize: 16,
    lineHeight: 2,
    a: {
      color: "#288bd0",
    },
  },
  main: {
    layout: {
      background: "#f5f5f5",
      border: "1px solid #e3e3e3",
      p: "10px",
      m: "20px 0",
      ul: {
        width: "100%",
        p: "0",
        m: "0",
        display: "flex",
        li: {
          width: ["99%", "33%", "33%"],
          padding: "10px 20px 14px 10px",
          cursor: "pointer",
          textAlign: "center",
        },
      },
      h5: {
        fontSize: "1.30rem",
      },
      label: {
        paddingLeft: "10px",
        display: "inline",
      },
    },
    layout1: {
      ul: {
        li: {
          listStyle: "disc",
        },
      },
      p: {
        span: {
          display: "block",
        },
      },
      button: {
        backgroundImage: "linear-gradient(#1b9ff2 , #1b9ff2 )",
      },
    },
    layout2: {
      h3: {
        color: "#085788",
        fontWeight: "600",
        fontSize: "20px",
      },
      ul: {
        padding: "0px",
        display: "flex",
        li: {
          paddingLeft: "10px",
          width: ["100%", "100%", "50%"],
          img: {
            padding: "1%",
          },
        },
      },
    },
  },
  package: {
    div: {
      input: {
        height: "0px",
      },
    },
  },
  // modal:{
  //   div:{
  //     div:{
  //       zIndex:1,
  //     }
  //   }
  // },
  banner: {
    green: {
      padding: "10px 0px",
      minHeight: "626px",
      fontFamily: "gotham-book",
      textAlign: "center",
      h2: {
        color: "#fff",
        fontSize: "58px",
        lineHeight: "80px",
        marginBottom: "12px",
        letterSpacing: "0.1px",
        marginTop: "20px",
      },
      h3: {
        color: "#fff",
        fontSize: "28px",
        lineHeight: "41px",
        margin: "0px",
        letterSpacing: "0.2px",
      },
      // button:{
      //   backgroundImage: 'linear-gradient(#B7D389, #418808)',
      // }
    },
    general: {
      paddingTop: "340px",
      fontFamily: "gotham-book",
      textAlign: "center",
      h2: {
        color: "#085788",
      },
      // button:{
      //   background: '#085788'
      // }
    },
  },
  footer: {
    fontSize: 18,
    textAlign: "center",
    div: {
      display: "block",
    },
    a: {
      textDecoration: "underline",
    },
    h3: {
      fontWeight: "500",
      fontSize: "1.75rem",
      margin: "10px 0px 0px 0px",
    },
    Button: {
      background: "none",
    },
  },

  carousel: {
    height: 500,
    overflow: "hidden",
    pagination: {
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
    page: {
      bg: "rgba(123, 0, 0, 0.25)",
      "&.active": {
        bg: "red",
      },
    },
  },
  overlay: {
    SiteAccessRestrictionBackground: {
      position: "fixed",
      display: "block",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
      webkitBorderRadius: "10px",
      mozBorderRadius: "10px",
      oBorderRadius: "10px",
    },
    SiteAccessRestrictionBackgroundPopUp: {
      position: "absolute",
      width: "86%",
      top: "50%",
      left: "50%",
      fontSize: "20px",
      transform: "translate(-50%,-50%)",
      borderRadius: "10px",
      backgroundColor: "white",
      padding: "30px",
      textAlign: "left",
      fontStyle: "bold",
      zIndex: 1,
    },
  },
};

export default themeStyle;
